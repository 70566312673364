<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Voter Tags</div>
    <div class="
          col-lg-7 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
      <button v-if="this.allowtagadditionaccess == 1" type="button" @click="addTagModalOpen()" class="header-btn-outer btn btn-primary">
        Add Tag
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="tagList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true" :rows="30"
      responsiveLayout="scroll" :lazy="true" :loading="loading" :rowHover="true" :totalRecords="totalRecords"  @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" dataKey="au1">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="Model" header="Tag Name" headerStyle="width: 60%" bodyStyle="width: 60%">
        <template #body="{ data }">
          <div>
            <span class="label-heading text-capitalize">{{
              data.au2 ? data.au2 : "N/A"
            }}</span>
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div>
            <span v-if="data.au3 == 1" class="status-active ms-2">Active</span>
            <span v-if="data.au3 == 0" class="status-inactive ms-2">Inactive</span>
          </div>
        </template>
      </Column>
      <Column field="Action" header="Action" class="justify-content-center" headerStyle="width: 20%"
        bodyStyle="width: 20%">
        <template #body="{ data }">
          <button :disabled="this.allowtagremoveaccess == 0" type="button" class="btn custom-view-detail-btn" @click="editTagModalOpen(data)">
            Edit Details
          </button>
        </template>
      </Column>
    </DataTable>
  </div>

  <div class="modal-mask" v-if="addTagDialog">
    <div class="modal-dialog modal-md modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add Tag</h5>
          <button type="button" class="btn-close" @click="addeditTagModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label for="formTagNameInput" class="form-label">Tag Name</label>
                <input type="text" v-model="add.tagname" class="form-control text-capitalize" id="formTagNameInput"
                  placeholder="Enter Tag Name" autocomplete="off" maxlength="25" />
                <div class="custom-error" v-if="v$.add.tagname.$error">
                  {{ v$.add.tagname.$errors[0].$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">Status<span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="add.tagstatus" name="active-status" id="active"
                    autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                  <input type="radio" class="btn-check" v-model="add.tagstatus" name="inactive-satatus" id="inactive"
                    autocomplete="off" value="0" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                </div>
                <div class="custom-error" v-if="v$.add.tagstatus.$error">
                  {{ v$.add.tagstatus.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-12 text-end">
              <button type="button" class="btn user-role-btn text-center" @click="addNewTag" :disabled="showloader">
                <span v-if="!showloader">Save</span>
                <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader"></div>
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-mask" v-if="editRoleDialog">
    <div class="modal-dialog modal-md modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Tag</h5>
          <button type="button" class="btn-close" @click="addeditTagModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-fixed-height">
            <div v-if="!showmodalloader">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label for="formTagNameInput" class="form-label">Tag Name</label>
                    <input type="text" v-model="edit.tagname" class="form-control text-capitalize" id="formTagNameInput"
                      placeholder="Enter Tag Name" autocomplete="off" maxlength="25" />
                    <div class="custom-error" v-if="v$.edit.tagname.$error">
                      {{ v$.edit.tagname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Status<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="edit.tagstatus" name="active-status" id="active"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                      <input type="radio" class="btn-check" v-model="edit.tagstatus" name="inactive-satatus" id="inactive"
                        autocomplete="off" value="0" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                    </div>
                    <div class="custom-error" v-if="v$.edit.tagstatus.$error">
                      {{ v$.edit.tagstatus.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-12 text-end">
            <button type="button" class="btn user-role-btn text-center" @click="updateUserRole" :disabled="showloader">
              <span v-if="!showloader">Update</span>
              <div class="
                          spinner-border
                          text-light
                          custom-spinner-loader-btn
                        " role="status" v-if="showloader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ApiService from "../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      loading: false,
      tagList: [],
      addTagDialog: false,
      editRoleDialog: false,
      add: {
        tagname: '',
        tagstatus: "",
      },
      edit: {
        tagname: '',
        tagstatus: "",
      },
      showloader: false,
      errormsg: "",
      roleid: "",
      showmodalloader: false,
      totalRecords: 0,
      page_no: 0,
      user_info: '',
      allowtagadditionaccess: '',
      allowtagremoveaccess: '',
    };
  },
  validations() {
    return {
      add: {
        tagname: { required: helpers.withMessage("Please enter tag name", required) },
      tagstatus: {
        required: helpers.withMessage("Please select status", required),
      },
      },
      edit: {
        tagname: { required: helpers.withMessage("Please enter tag name", required) },
      tagstatus: {
        required: helpers.withMessage("Please select status", required),
      },
      },
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.gettaglist();
    this.user_info = JSON.parse(localStorage.user);
    this.allowtagadditionaccess = this.user_info.ak197;
    this.allowtagremoveaccess = this.user_info.ak198;
  },
  methods: {
    gettaglist(item) {
      this.loading = true;
      this.ApiService.gettaglist(item).then((data) => {
        if (data.status == 200) {
          this.tagList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.tagList = '';
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.gettaglist({ page_no: this.page_no });
    },
    addTagModalOpen() {
      this.addTagDialog = true;
      this.add.tagname = '';
      this.add.sttagstatusatus = '';
    },

    addeditTagModalClose() {
      this.editRoleDialog = false;
      this.addTagDialog = false;
    },

    editTagModalOpen(data) {
      this.showmodalloader = true;
      this.roleid = data.au1;
      this.edit.tagname = data.au2;
      this.edit.tagstatus = data.au3;
      this.editRoleDialog = true;
      this.showmodalloader = false;
    },
    addNewTag() {
      this.errormsg = '';
      this.v$.add.$validate();
      let fields = {};
      fields["au2"] = this.add.tagname;
      fields["au3"] = this.add.tagstatus;
      this.v$.$validate();
      if (!this.v$.add.$error) {
        this.showloader = true;
        this.ApiService.createtag(fields).then((items) => {
          if (items.success == true) {
            this.showloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.addTagDialog = false;
            this.gettaglist();
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      }
    },
    updateUserRole() {
      this.errormsg = '';
      let fields = {};
      fields["au1"] = this.roleid;
      fields["au2"] = this.edit.tagname;
      fields["au3"] = this.edit.tagstatus;
      this.v$.edit.$validate();
      if (!this.v$.edit.$error) {
        this.showmodalloader = true;
        this.showloader = true;
        this.ApiService.createtag(fields).then((data) => {
          if (data.success == true) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.editRoleDialog = false;
            this.edit.tagname = '';
            this.edit.tagstatus = '';
            this.errormsg = "";
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.gettaglist();
            this.showmodalloader = false;
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
            this.showmodalloader = false;
          }
        });
      }
    },
  },
};
</script>
  
<style scoped>
.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  margin-right: 12px;
  min-width: 80px;
}

.user-role-btn:focus {
  box-shadow: none;
}

.modal-body-fixed-height {
  min-height: 140px;
}
.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}
</style>
  
  